/*$fontName: 'Lato', sans-serif;*/
$fontName: 'Poppins', sans-serif;

table.nislo-datatable {
    /*display: grid; */
    min-width: 980px;
}
.fw table.nislo-datatable {
    /*display: grid; */
    min-width: 100%;
}

.fw .table-wrapper {
  min-height: auto !important;
}

th:focus, td:focus { outline: 0!important;}


table.nislo-datatable th, table.nislo-datatable td { padding-left: 3px; padding-right: 3px; }

table.nislo-datatable {

  thead {

    th {
      color: #2b2b43; font-size: 13px; font-weight: 500;
      border-bottom:1px solid #d4d7d6;
      padding-top: 10px; padding-bottom: 10px;

      white-space: nowrap;

      i.sortArrow { visibility: hidden; }

      &.sortable-column { cursor: pointer; }
      &.sort, &.sortable-column:hover { color: #6381ff; }
      &.sort i.sortArrow { display: inline-block; margin-left: 5px; visibility: visible; }
    }

    tr.font-small th { font-size: 13px; }
  }
}





  table.nislo-datatable tbody td {

    padding-top: 10px; padding-bottom: 10px;
    border-bottom:1px solid rgba(212, 215, 230, 0.5);
    font-size: 1rem; font-weight: 500; color: #6c7293;

  }

  table.nislo-datatable tbody tr.font-small td { font-size: 11px; color: #595d6e; }

  table.nislo-datatable tbody td.fieldName .fieldNameInner { display: flex; align-items: center; }
  table.nislo-datatable tbody td.fieldFeed { white-space: nowrap; position: relative; }

  table.nislo-datatable tbody td .num {
     font-family: $fontName; font-size: 14px; font-weight: 500;color: #6c7293;
  }
  table.nislo-datatable tbody td .rate {
     font-family: $fontName; font-size: 15px; font-weight: 600;color: #6c7293;
  }

  table.nislo-datatable tbody td .text-red { color:#db7979; }
  table.nislo-datatable tbody td .text-orange { color:#f4a661; }
  table.nislo-datatable tbody td .text-green { color:#50ce87; }
  table.nislo-datatable tbody td .text-blue { color:#5f7cf6; }


  table.nislo-datatable tbody td  i.deadline-circle {
    /*background: #8297ff;*/
    background: #d4d7e6 ;
    width: fit-content;
    height: fit-content;
    position: relative;
    display: inline-block;
    border-radius: 15px;
     color: black;
     font-size: 10px;
     padding: 1px 4px 1px 1px;
    }



  .nislo-datatable .name-badge, .nislo-dropdown .name-badge {
    position: relative; display: flex; width: 50px; height: 50px; margin-right: 20px;
    border-radius: 50px; text-align: center; align-items: center; justify-content: center;
    font-family: $fontName; font-size: 16px; font-weight: 600;
    color: #fff;
    /*opacity: 0.7;*/
  }
  .nislo-datatable .name-badge-small, .nislo-dropdown .name-badge-small {
    width: 30px; height: 30px; margin: 0 auto; border-radius: 50px; font-size: 10px;
    min-width: 30px; min-height: 30px;
  }

  .nislo-datatable .badge-supervisor, .nislo-dropdown .badge-supervisor {

      border: solid 1px #a1a6c0; background-color: #f6f6f6;   color: #595d6e;
      margin-left:4px!important; margin-right: 0; opacity: 1;
  }
  .nislo-datatable .badge-supervisor.own {

      border: solid 1px #6c7293; background-color: #6c7293; margin-left:4px!important;  margin-right: 0;
      color: #fff; z-index: 50;
  }
  .nislo-datatable .badge-supervisor.sort {

    margin-left: -15px!important; opacity: 1;
  }

  .nislo-datatable .badge-supervisor.mx-auto { margin:0!important; margin:0 auto!important; }


  .nislo-datatable .name-badge.badge-clr-darkblue, .nislo-dropdown .name-badge.badge-clr-darkblue { background-color:#6c7293; }
  .nislo-datatable .name-badge.badge-clr-darkblue, .nislo-dropdown .name-badge.badge-clr-darkblue { background-color: #6c7293; }
  .nislo-datatable .name-badge.badge-clr-darkred, .nislo-dropdown .name-badge.badge-clr-darkred { background-color: #d66377; }
  .nislo-datatable .name-badge.badge-clr-darkyellow, .nislo-dropdown .name-badge.badge-clr-darkyellow { background-color: #d7aa32; }
  .nislo-datatable .name-badge.badge-clr-darkgreen, .nislo-dropdown .name-badge.badge-clr-darkgreen { background-color: #469c6b; }

  .nislo-datatable .name-badge.badge-clr-white {
      border: solid 1px #a1a6c0; background-color: #f6f6f6; color: #595d6e;
  }


  .nislo-datatable .name-badge .fav {
    position: absolute;  display: flex; bottom: 0; right: -10px;
    justify-content: center; align-items: center;
    color:#d4d7e6; font-size: 15px;;
    width: 25px; height: 25px; background-color: #fff;
    border-radius: 25px;
  }

  .nislo-datatable .name-badge .fav-red {
    color:#fd397a;
  }



  .nislo-datatable .writer-name {
    position: relative; display: flex; flex-direction: column;
    font-size: 13px; font-weight: 500; color: #595d6e;
    white-space: nowrap;

  }




  .nislo-datatable .writer-name span {
    position: relative; display: block; color: #9b9b9b;
    font-size: 11px;   font-weight: 500; font-family: $fontName; line-height: 14px
  }
  .nislo-datatable .writer-name span i {
    position: relative; display: inline-block; font-style: normal;
      font-family: monospace;font-size: 30px; line-height: 14px; vertical-align: bottom;
      margin-left: -5px; margin-right: -3px;
  }

  .nislo-datatable .writer-name span.online i { color: #50ce87 }
  .nislo-datatable .writer-name span.offline i { color: #d87087 }
  .nislo-datatable .writer-name span.away i { color: #f4a661 }


  .nislo-datatable td .star-on, .star-on {color: #ffb822;}
  .nislo-datatable td .star-off, .star-off {color: #d8d8d8;}


  .nislo-datatable .badge, .nislo-badge.badge {
    font-family: $fontName; font-size: 11px;
    font-weight: 500; color: #a1a6c0; text-align: center;
    border-radius: 20px; padding-left:10px;padding-right:10px;
  }
  .nislo-datatable .badge-green, .nislo-badge.badge-green {color: #308f5a; background-color: #c8f3cf; }
  .nislo-datatable .badge-yellow, .nislo-badge.badge-yellow  {color: #969049; background-color: #fbf38d; }
  .nislo-datatable .badge-orange, .nislo-badge.badge-orange {color: #c57e3f; background-color: #f9d2b0; }
  .nislo-datatable .badge-lightorange, .nislo-badge.badge-lightorange {color: #84552b; background-color: rgba(245, 148, 42, .1); }
  .nislo-datatable .badge-gray, .nislo-badge.badge-gray {color: #a1a6c0; background-color: #e9ebf2; }
  .nislo-datatable .badge-red, .nislo-badge.badge-red {color: #d87087!important; background-color: #EBB7C3; }
  .nislo-datatable .badge-darkred, .nislo-badge.badge-darkred {color: #fff!important; background-color: #d66377; }
  .nislo-datatable .badge-white, .nislo-badge.badge-white {color:#a1a6c0!important; background-color:#fff;border:1px solid rgba(108, 114, 147, 0.5); }



/**/

.nislo-table-listview {}
.nislo-table-cardview {}

table.nislo-table-cardview tbody td { vertical-align: top }
/*table.nislo-table-cardview tbody td.fieldName .fieldNameInner,*/
table.nislo-datatable tbody td.fieldName .fieldNameInner2
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

table.nislo-table-cardview .name-badge,
table.nislo-datatable tbody td.fieldName .fieldNameInner2 .name-badge { margin: 0 0 10px 0; }
table.nislo-table-cardview .writer-name span,
table.nislo-datatable tbody td.fieldName .fieldNameInner2 .writer-name span { margin: auto; }

table.nislo-table-cardview .fieldScore .scoreArea,
table.nislo-datatable .fieldScore .scoreArea
{
  font-family: $fontName;
  display: flex; flex-direction: column;
  justify-content: space-between; margin-bottom: 20px;
}

table.nislo-table-cardview .fieldScore .scoreArea span,
table.nislo-datatable .fieldScore .scoreArea span
{
  font-weight: 500; font-size: 11px;
  color: #a7abc3;
}

table.nislo-table-cardview .fieldScore .scoreArea strong,
table.nislo-datatable .fieldScore .scoreArea strong
{
  font-weight: 600; font-size: 24px;
  color: #6c7293;
}
/**/

table.nislo-table-cardview .detail,
table.nislo-datatable .detail
{
  font-size: 12px; font-weight: 500; color: #a8abbd;
  margin: 20px 0 10px;
}
table.nislo-table-cardview .detail span,
table.nislo-datatable .detail span {
  font-size: 22px; color: #6c7293;
}

/**/

.nislo-datatable tbody.color-line tr.hover td { background-color: #f5f5f9;}

.nislo-datatable tbody.color-line tr.selected td { background-color:#8297ff; color:#fff;}

/**/
.nislo-datatable .feedArea { display: flex; font-size: 11px; font-weight: 500; color: #6c7293; margin-bottom: 10px; }
.nislo-datatable .feedArea .feedName { width: 35%;}

.nislo-datatable .listTags { position: relative; display:block; }
.nislo-datatable .listTags span {
  position: relative; display: block; float: left;
  font-size: 11px; font-weight: 500; color: #6c7293;
  padding: 5px 10px; border-radius: 2px;
  background:#f7f8fa; margin: 0 10px 10px 0;
}

.nislo-datatable  span.listTags {
  position: relative; display: block; float: left;
  font-size: 11px; font-weight: 500; color: #6c7293;
  padding: 5px 10px; border-radius: 2px;
  background:#f7f8fa; margin: 0 10px 10px 0;
}



/*
.nislo-datatable .listTags span:last-child { clear: both; }
*/

/**/


.nislo-datatable .popover .popover-header {
  font-family: $fontName;
  font-size: 13px; font-weight: 500; color: #15161d;
  -webkit-font-smoothing: antialiased!important;
}

.nislo-datatable .popover-body {
  float: none!important;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased!important;
}
.nislo-datatable .popover-body .line {
  display: block;
  font-size: 11px;
  font-weight: 500;
  line-height: 2;
  color: #6c7293;
  -webkit-font-smoothing: antialiased!important;
}



.nislo-datatable .tooltipFeedback {
  position: absolute; display: none; bottom: 80%; left: -30%;
  background-color: #fff; padding: 20px; border-radius: 4px;
  box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);

}


.nislo-datatable .tooltipFeedback:after {
    position: absolute; display: block; content: " ";
    bottom: -10px; left: 0; right: 0; margin: auto;
    width: 0px; height: 0px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid #fff;
}

.nislo-datatable .tooltipFeedback .title {
  position: relative; display: block;
  padding: 10px 20px; margin: -20px -20px 15px -20px;
  font-size: 13px; font-weight: 500; color: #595d6e;
  border-bottom:1px solid #f7f7f7;

}

.nislo-datatable  .tooltipFeedback .feedName { width: 80px; }
/**/
/*
.nislo-table-scroll tbody {
display:block; overflow-y:auto;  overflow-x: hidden;
height:250px; width:100%;
}
*/
.nislo-table-scroll tbody td:first-child { /*padding-left: 20px;*/}
.nislo-table-scroll tbody td:last-child { /*padding-right: 20px;*/}

.nislo-table-scroll tbody td input.clientId { opacity: 0; }

.nislo-table-scroll thead tr, .nislo-table-scroll tbody tr {
  /*display:table; width:100%; table-layout:fixed;*/
}

.nislo-table-scroll thead th { font-size: 13px; font-weight: 500;  color: #2b2b43; }


.nislo-table-scroll .bootstrap-select { width: 100%!important }

.nislo-datatable .form-control {
  width: 100%!important; height: 30px;
  font-size:11px; font-weight:normal;
}

.nislo-datatable th .ng-select .ng-select-container { min-height: 30px;}
.nislo-datatable th .ng-select .ng-value-container  {
  color: #595d6e!important; font-weight: normal!important;
}
.nislo-datatable th .ng-select .ng-value-container .ng-value {
  font-size:11px!important;  color: #595d6e!important;
}

.nislo-table-scroll .small-field { width: 75px; }
.nislo-table-scroll .fieldSupervisor div { float: right;}
.nislo-table-scroll .fieldSupervisor button { float: right;}


.nislo-table-scroll .fieldSupervisor .name-badge { margin-left: -20px; }


.nislo-table-scroll .btnAddRemove {
position: relative; display: inline-block; text-align: center; vertical-align: baseline;
color: #8297ff; font-size: 20px; line-height: 28px; margin-left: 10px;
border-radius: 30px; width: 30px; height: 30px; border:1px solid #d4d7e6;
background-color: #fff;;
}
.nislo-table-scroll .btnAddRemove { font-size: 10px; }

.nislo-table-scroll .btnAddRemove i.fa-minus { display: none; }
.nislo-table-scroll .btnAddRemove i.fa-plus { display: block; }

.nislo-table-scroll .btnAddRemove.added i.fa-minus { display: block; }
.nislo-table-scroll .btnAddRemove.added i.fa-plus { display: none; }



/*
.ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 106px;
}
*/



.title-fix {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.customtooltip .tooltip-inner { max-width: 400px; text-align: left; }

