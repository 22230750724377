@media (min-width: 1025px){
    .kt-aside__brand{
        background-color:#1a1a27;
        -webkit-box-shadow:none;
        box-shadow:none
    }
    .kt-aside--minimize .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg { width: 39px!important; height: 39px!important; margin-top: 7px;} 
    .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg g [fill]{
        -webkit-transition:fill 0.3s ease;
        transition:fill 0.3s ease;
        fill:#494b74
    }
     .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg:hover g [fill]{
        -webkit-transition:fill 0.3s ease;
        transition:fill 0.3s ease
    }
    .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler:hover svg g [fill]{
        -webkit-transition:fill 0.3s ease;
        transition:fill 0.3s ease;
        fill:#5d78ff
    }
    .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler:hover svg:hover g [fill]{
        -webkit-transition:fill 0.3s ease;
        transition:fill 0.3s ease
    }
}
@media (max-width: 1024px){
    .kt-header-mobile{
        background-color:#1a1a27;
        -webkit-box-shadow:0px 1px 9px -3px rgba(0,0,0,0.1);
        box-shadow:0px 1px 9px -3px rgba(0,0,0,0.1)
    }
    .kt-header__topbar--mobile-on .kt-header-mobile{
        -webkit-box-shadow:none;
        box-shadow:none
    }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler span{
        background:#484f66
    }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler span::before,.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler span::after{
        background:#484f66
    }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler:hover span{
        background:#fff
    }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler:hover span::before,.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler:hover span::after{
        background:#fff
    }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler.kt-header-mobile__toolbar-toggler--active span{
        background:#fff
    }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler.kt-header-mobile__toolbar-toggler--active span::before,.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler.kt-header-mobile__toolbar-toggler--active span::after{
        background:#fff
    }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-topbar-toggler i{
        color:#484f66
    }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-topbar-toggler.kt-header-mobile__toolbar-topbar-toggler--active i,.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-topbar-toggler:hover i{
        color:#ffffff
    }
}
 